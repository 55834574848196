@import '/src/styles/variables';
@import '/src/styles/typo';

@mixin title-with-theme {color: $white; padding: 13px 20px; border-radius: 12px 12px 0 0; font-weight: normal; margin: 0 -20px;}
.box {background: $bg-box; border-radius: $radius; padding: 0 $padding;
  @media (max-width: $mobile) {padding: 0 16px; border-radius: $radius-small;}
  &_title {@extend .title;
    &__primary {padding: $padding 0 25px; background-color: $white;}
    &__light {@include title-with-theme; background: $blue;
      @media (max-width: $mobile) {margin: 0 -16px;}
    }
    &__dark {@include title-with-theme; background: $dark-gray;
      @media (max-width: $mobile) {margin: 0 -16px;}
    }
  }
}
