.row {margin: 0 (-$padding); display: flex; flex-wrap: wrap;
  &__noindent {margin: 0;
    & > .col {padding: 0;}
  }
  &__middle {margin: 0 (-$padding-small);
    & > .col {padding: 0 $padding-small;}
  }
  &__small {margin: 0 -4px;
    & > .col {padding: 0 4px;}
  }
}
.col {padding: 0 $padding; box-sizing: border-box;
  &__right {order: -1;}
  &__17 {width: 17%; flex: 0 0 17%;}
  &__20 {width: 20%; flex: 0 0 20%;}
  &__25 {width: 25%; flex: 0 0 25%;}
  &__33 {width: 33.33%; flex: 0 0 33.33%;}
  &__30 {width: 30%; flex: 0 0 30%;}
  &__40 {width: 40%; flex: 0 0 40%;}
  &__50 {width: 50%; flex: 0 0 50%;}
  &__60 {width: 60%; flex: 0 0 60%;}
  &__66 {width: 66.66%; flex: 0 0 66.66%;}
  &__70 {width: 70%; flex: 0 0 70%;}
  &__75 {width: 75%; flex: 0 0 75%;}
  &__80 {width: 80%; flex: 0 0 80%;}
  @media (max-width: $tablet) {
    padding: 0 16px;
    &__tablet__17 {width: 17%; flex: 0 0 17%;}
    &__tablet__20 {width: 20%; flex: 0 0 20%;}
    &__tablet__25 {width: 25%; flex: 0 0 25%;}
    &__tablet__30 {width: 30%; flex: 0 0 30%;}
    &__tablet__33 {width: 33.33%; flex: 0 0 33.33%;}
    &__tablet__40 {width: 40%; flex: 0 0 40%;}
    &__tablet__45 {width: 45%; flex: 0 0 45%;}
    &__tablet__50 {width: 50%; flex: 0 0 50%;}
    &__tablet__55 {width: 55%; flex: 0 0 55%;}
    &__tablet__60 {width: 60%; flex: 0 0 60%;}
    &__tablet__66 {width: 66.66%; flex: 0 0 66.66%;}
    &__tablet__75 {width: 75%; flex: 0 0 75%;}
    &__tablet__70 {width: 70%; flex: 0 0 70%;}
    &__tablet__100 {width: 100%; flex: 0 0 100%;}
  }
  @media (max-width: $mobile) {width: 100%; flex: 0 0 100%; order: 0;
    &__mobile__17 {width: 17%; flex: 0 0 17%;}
    &__mobile__20 {width: 20%; flex: 0 0 20%;}
    &__mobile__25 {width: 25%; flex: 0 0 25%;}
    &__mobile__30 {width: 30%; flex: 0 0 30%;}
    &__mobile__33 {width: 33.33%; flex: 0 0 33.33%;}
    &__mobile__40 {width: 40%; flex: 0 0 40%;}
    &__mobile__50 {width: 50%; flex: 0 0 50%;}
    &__mobile__60 {width: 60%; flex: 0 0 60%;}
    &__mobile__66 {width: 66.66%; flex: 0 0 66.66%;}
    &__mobile__70 {width: 70%; flex: 0 0 70%;}
    &__mobile__75 {width: 75%; flex: 0 0 75%;}
    &__mobile__100 {width: 100%; flex: 0 0 100%;}
  }
}
