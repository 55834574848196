.text {font-size: $text-default; line-height: 1.428;
  &__small {font-size: 0.857rem; line-height: 1.5;}
  &__large {font-size: 1.143rem; line-height: 1.375;}
  &__largest {font-size: 1.286rem; line-height: 1.388;}
  &__primary {color: $primary;}
}

.title {font-size: 1.571rem; line-height: 1.363; font-weight: normal;
  &__large {font-size: 2rem; line-height: 1.142; font-weight: bold;}
  &__largest {font-size: 2.714rem; line-height: 1.157; font-weight: bold;}
}
