@import '/src/styles/variables';

.icon {width: $icon-size; height: $icon-size; display: inline-block; vertical-align: top;
  background-image: url('../../../../public/images/icons.svg'); background-position: var(--bg-position);
  &__mask {mask-image: url('../../../../public/images/icons.svg'); mask-position: var(--bg-position);
    background: var(--bg-color);
  }

  &__white {--bg-color: #{$white};}
  &__primary {--bg-color: #{$primary};}
  &__secondary {--bg-color: #{$secondary};}
  &__gray {--bg-color: #{rgba($dark-gray, 0.2)};}
  &__danger {--bg-color: #{$red};}
  &__black {--bg-color: #{$black};}

  &__search {--bg-position: 0 0;}
  &__next {--bg-position: -30px 0;}
  &__prev {--bg-position: -30px 0; transform: rotate(180deg);}
  &__user {--bg-position: -60px 0;}
  &__help {--bg-position: -90px 0;}
  &__arrow {--bg-position: -120px 0;}
  &__arrowleft {--bg-position: -120px 0; transform: rotate(180deg);}
  &__dropdown {--bg-position: -150px 0;}
  &__lock {--bg-position: -180px 0;}
  &__pdf {--bg-position: -210px 0;}
  &__check {--bg-position: -240px 0;}
  &__item {--bg-position: -270px 0;}
  &__chat {--bg-position: 0 -30px;}
  &__archive {--bg-position: -30px -30px;}
  &__checkbox {--bg-position: -60px -30px; height: 18px; width: 18px;}
  &__phone {--bg-position: -120px -30px; height: 30px; width: 30px;}
  &__email {--bg-position: -210px -30px; height: 30px; width: 30px;}
  &__location {--bg-position: -180px -30px; height: 30px; width: 30px;}
  &__planet {--bg-position: -150px -30px; height: 30px; width: 30px;}
  &__close {--bg-position: -60px -60px; height: 30px; width: 30px;}
  &__menu {--bg-position: -150px -60px; height: 30px; width: 30px;}
  &__filter {--bg-position: -180px -60px; height: 30px; width: 30px;}
  &__dropup {--bg-position: -210px -60px; height: 30px; width: 30px;}
  &__file {--bg-position: -240px -30px; height: 30px; width: 30px;
    &_large {--bg-position: -90px -60px; height: 60px; width: 60px;}
  }
  &__calendar {--bg-position: -30px -60px; height: 30px; width: 30px;}

  &__nextsmall {--bg-position: -270px -30px;}
}
