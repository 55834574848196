@import '/src/styles/variables';

.form {
  &_group {width: 100%; position: relative;
    &_icon {position: absolute; top: 5px; right: 0;}
    &__required {
      &::after {content: '*'; display: block; color: $red; position: absolute;
        line-height: 40px; top: 0; left: calc(100% + 4px);}
    }
    &__disabled {position: absolute; top: 5px; left: calc(100% + 12px);
      @media (max-width: $tablet) {position: static; margin-left: 4px;}
    }
    &__bordered {
      .form_control {border-color: rgba($dark-gray, 0.2);
        &:focus, &.focus {border-color: $primary;}
      }
    }
    &__error {
      .form_control {border-color: $error;}
    }
    &__select {position: relative;}
    &_lock {display: flex; align-items: center;}
    &_selecticon {appearance: none; position: absolute; right: 10px; z-index: 10; top: 50%; transform: translateY(-50%); pointer-events: none;} 
  }
  &_control {border-radius: $radius-small; outline: none; line-height: $icon-size;
    padding: 4px 16px; display: inline-block; width: 100%; box-sizing: border-box;
    border: 1px solid $bg-input; background: $bg-input; margin: 0; height: 40px;
    font-size: 1rem; color: $text; position: relative;
    &:disabled {color: $subtext;}
    &::placeholder {color: $subtext; font-size: 1rem;}
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
  &_error {color: $error; margin-top: 4px;}
}

.checkbox, 
.radio {width: 18px; height: 18px; background: $bg-box;
  border: 1px solid rgba($gray, 0.5); transition: all 0.3s; margin-right: 8px;
  position: relative; top: -1px;
  display: inline-block; vertical-align: middle; box-sizing: border-box;
  &_wrapper {position: relative; display: inline-block; vertical-align: top;
    line-height: 18px; height: 18px;
    input {opacity: 0; width: 0; height: 0; position: absolute; pointer-events: none;}
  }
  &__error {border-color: $error;}
  &_icon {opacity: 0; width: 18px; height: 18px; display: inline-block;
    position: absolute; top: -1px; left: -1px;}
}

input:checked ~ .checkbox {background: $primary; border-color: $primary;
  .checkbox_icon {opacity: 1;}
}
input:checked + .radio {border-color: $primary;
  .radio_icon {opacity: 1;}
}

.checkbox {border-radius: 4px;}
.radio {border-radius: 50%;
  &_checkbox {border-radius: 4px;
    .radio_icon {width: 18px; height: 18px; border-radius: 4px;}
  }
  &_item {
    &:not(:last-child) {margin-right: 30px;
      @media (max-width: $tablet) {margin-right: 20px;}
    }
    input {opacity: 0; width: 0; height: 0; position: absolute; pointer-events: none;}
  }
  &_icon {width: 10px; height: 10px; background: $primary; border-radius: 50%; top: 50%; left: 50%; transform: translate(-50%, -50%); transition: .3s;}
}

.multiselect {padding: 7px 7px 7px $padding; background: $bg; border-radius: $radius;
  box-sizing: border-box; box-sizing: border-box; position: relative;
  &_scroll {overflow: hidden; overflow-y: auto; height: 100%;
    &::-webkit-scrollbar {width: 12px;}
    &::-webkit-scrollbar-thumb {background: rgba($black, 0.2); border-radius: 6px;}
  }
  &_item {display: block; margin: 12px 0;}
  &_spinner {position: absolute; top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%);}
}

.select {appearance: none;
  &_item {color: $black}
  &_notchoose {color: $silver;}
}

.textarea {background: $white; border-radius: $radius-small; min-height: 180px; padding: 4px 16px; outline: 0; box-sizing: border-box; resize: none; width: 100%; height: 100%;}

.date {
  position: relative;
  &_icon {position: absolute; right: 10px; top: 50%; transform: translateY(-50%); pointer-events: none; background: #fff;}
}
