@import '/src/styles/variables';

@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

.spinner {width: var(--size); height: var(--size); border: $primary var(--wight) solid; border-top-color: transparent;
  animation: spin 1s linear infinite; content: ""; top: 0; left: 0; bottom: 0; right: 0; margin: auto;
  border-radius: 50%; pointer-events: none; display: block; --size: 25px; --wight: 4.5px;
  &__small {--size: 13px; --wight: 3px;}
  &__large {--size: 50px; --wight: 6px;}

  &__white {border: $white var(--wight) solid; border-top-color: transparent;}
}
