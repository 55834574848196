.onlymobile {display: none;
  @media(max-width: $mobile) {display: unset;}
}
.onlytable {display: none;
  @media(max-width: $tablet) {display: unset;}
  @media(max-width: $mobile) {display: none;}
}
.onlydesktop {
  @media(max-width: $tablet) {display: none;}
}
.excludeformobile {
  @media(max-width: $mobile) {display: none;}
}
.excludefortablet {
  @media(max-width: $tablet) {display: none;}
  @media(max-width: $mobile) {display: unset;}
}
.excludefordesktop {display: none;
  @media(max-width: $tablet) {display: unset;}
}
