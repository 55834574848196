@import '/src/styles/variables';
@import '/src/styles/typo';

@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

.button {padding: 4px 25px; display: inline-block; border-radius: $radius-small;
  color: var(--color); background: var(--bg-color); border: 1px solid var(--border-color); font-size: 1rem;
  outline: none; line-height: $icon-size; cursor: pointer;
  justify-content: center; align-items: center; white-space: nowrap; box-sizing: border-box;
  &__primary {--bg-color: #{$primary}; --color: #{$text-opposite}; --border-color: var(--bg-color);
    &:hover, &.hover {--bg-color: #{$primary-active};}
  }
  &__secondary {--bg-color: #{$secondary}; --color: #{$text-opposite}; --border-color: var(--bg-color);
    &:hover, &.hover {--bg-color: #{$secondary-active};}
  }
  &__outline {--bg-color: none; --color: #{$primary}; --border-color: var(--color);
    &:hover, &.hover {--color: #{$primary-active};}
  }
  &__tertiary {--bg-color: #{$dark-gray}; --color: #{$text-opposite}; --border-color: #{$black};
    &:hover, &.hover {--color: #{$text-opposite}; --bg-color: #{$black};}
  }
  &__danger {--bg-color: #{rgba(255, 49, 49, 0.1)}; --color: #{$text-opposite}; --border-color: var(--bg-color);
    &:hover, &.hover {--color: #{$primary-active};}}
  &:disabled, &__disabled {--bg-color: #{$silver}; --color: #{$text-opposite};
    --border-color: var(--bg-color); cursor: auto; pointer-events: none;}
  &__icon {padding: 4px; width: 40px; text-align: center;}
  &__link {padding-right: 9px;}
  &__block {width: 100%;}
  &__loading {pointer-events: none; opacity: 0.6; position: relative; color: transparent;
    &::after {position: absolute; width: 13px; height: 13px; border: $white 3px solid; border-top-color: transparent;
      animation: spin 1s linear infinite; content: ""; top: 0; left: 0; bottom: 0; right: 0; margin: auto;
      border-radius: 50%; pointer-events: none;
    }
  }
  &__large {font-size: 1.143rem; line-height: $icon-size; font-weight: bold; padding: 14px 25px;}
  &__tab {}
}
.tab {text-transform: uppercase; padding-left: 28px; padding-right: 28px; letter-spacing: 0.05rem;
  --bg-color: #{$bg-secondary}; --color: #{$text-opposite}; --border-color: var(--bg-color);
  &__active {--bg-color: #{$primary};}
  &:hover {--bg-color: #{$primary};}
}
