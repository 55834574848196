@font-face {font-family: 'OpenSans'; font-weight: normal; font-style: normal; font-display: swap;
  src: local('OpenSans'), url('../../public/fonts/OpenSans.eot') format('embedded-opentype');}
@font-face {font-family: 'OpenSans'; font-weight: normal; font-style: normal; font-display: swap;
  src: local('OpenSans'), url('../../public/fonts/OpenSans.eot?#iefix') format('embedded-opentype');}
@font-face {font-family: 'OpenSans'; font-weight: normal; font-style: normal; font-display: swap;
  src: local('OpenSans'), url('../../public/fonts/OpenSans.ttf') format('truetype');}
@font-face {font-family: 'OpenSans'; font-weight: normal; font-style: normal; font-display: swap;
  src: local('OpenSans'), url('../../public/fonts/OpenSans.woff') format('woff');}
@font-face {font-family: 'OpenSans'; font-weight: normal; font-style: normal; font-display: swap;
  src: local('OpenSans'), url('../../public/fonts/OpenSans.woff2') format('woff2');}

@font-face {font-family: 'OpenSans'; font-weight: bold; font-style: normal; font-display: swap;
  src: local('OpenSans-Bold'), url('../../public/fonts/OpenSans-Bold.eot') format('embedded-opentype');}
@font-face {font-family: 'OpenSans'; font-weight: bold; font-style: normal; font-display: swap;
  src: local('OpenSans-Bold'), url('../../public/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype');}
@font-face {font-family: 'OpenSans'; font-weight: bold; font-style: normal; font-display: swap;
  src: local('OpenSans-Bold'), url('../../public/fonts/OpenSans-Bold.ttf') format('truetype');}
@font-face {font-family: 'OpenSans'; font-weight: bold; font-style: normal; font-display: swap;
  src: local('OpenSans-Bold'), url('../../public/fonts/OpenSans-Bold.woff') format('woff');}
@font-face {font-family: 'OpenSans'; font-weight: bold; font-style: normal; font-display: swap;
  src: local('OpenSans-Bold'), url('../../public/fonts/OpenSans-Bold.woff2') format('woff2');}
