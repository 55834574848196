@import '/src/styles/variables';
@import '/src/styles/typo';

.account {background: $bg; display: grid; grid-template-rows: auto 1fr auto; min-height: 100vh;
  @media (max-width: $tablet) {padding-top: 60px; grid-template-rows: 1fr auto;}
  &_header {
    @media (max-width: $tablet) {padding-top: 10px;}
    &__inner {
      @media (max-width: $tablet) {display: flex; justify-content: space-between; align-items: center; padding: 10px 16px; position: fixed; top: 0; left: 0; right: 0; background-color: $white; z-index: 9999;}
    }
    &_search {
      .header_login_inner {
        @media (max-width: $tablet) {max-height: 0; transition: 0s; overflow: hidden;}
      }
    }
  }
  &_content {box-sizing: border-box; display: grid; grid-auto-rows: auto auto 1fr auto; padding-bottom: $padding; grid-template-areas: "content"; grid-template-columns: minmax(295px, 100%);}
  &_inner {max-width: 1240px; padding: 0 $padding; margin: 0 auto; box-sizing: border-box; width: 100%;
    @media (max-width: $tablet) {background-color: $white; margin: 0; min-height: 60px; overflow: hidden; height: 100vh; transition: .24s; position: fixed; top: 0; left: 0; right: 0; overflow-y: auto; height: 0; padding: 0; z-index: 9999;}
    &__open, &__search {
      @media (max-width: $tablet) {max-height: 100%; height: 100%; padding: 60px 0 24px;}
    }
    &__search {transition: .5s;
      .account_linksmobile {max-height: 0; transition: 0s; overflow: hidden;}
    }
    &__open {transition: .5s;
      .header_login_inner, .menu_inner, .account_linksmobile {max-height: none; transition: 0s;}
    }
  }
  &_linksmobile {
    @media (max-width: $tablet) {max-height: 140px; transition: 1s linear .5s; overflow: hidden;}
  }
  &_footer {
    &_box {background-color: $bg-secondary !important; padding: 0 !important;
      @media (max-width: $mobile) {margin: 0 -20px; border-radius: 0 !important;}
    }
  }
  &_container {}
  &_linksmobile {display: none;
    @media (max-width: $tablet) {display: block;}
  }
}

.footer {color: $text-opposite; max-width: 1240px; margin: 0 auto; padding: 0 20px 20px; box-sizing: border-box; width: 100%;
  @media (max-width: $mobile) {padding-bottom: 0;}
  &_header {padding: 9px $padding; margin: 0 (-$padding); background: $gray;
    border-radius: $radius $radius 0 0;
    @media (max-width: $mobile) {border-radius: 0; margin-bottom: 12px; padding: 11px 16px; margin: 0 -16px 20px;}
    & > .footer_item {
      @media (max-width: $mobile) {margin-top: 0;}
    }
    &__noborder {border-radius: 0;}
    .footer_item {@extend .text__large; font-weight: bold; margin-bottom: 0;}
  }
  &_body {padding-bottom: 30px;}
  &_items {display: flex; flex-direction: column; margin-top: $padding;
    @media(max-width: $mobile) {margin-top: 0; margin-bottom: $padding;}
  }
  &_item {margin-bottom: 6px;
    @media (max-width: $mobile) {margin: 8px 0; }
  }
  &_logo {margin-top: 24px; margin-bottom: 14px; object-fit: contain; width: 100%;
    @media (max-width: $mobile) {margin-top: 12px;}
  }
  &_copyright {@extend .text__small;
    @media(max-width: $mobile) {text-align: center;}
  }
}

.header {
  &_wrapper {display: flex; justify-content: space-between;
    @media (max-width: $tablet) {flex-direction: column;}
    &__action {display: none;
      @media (max-width: $tablet) {display: flex; align-items: center; gap: 16px}
      &_item {background: none; border: none; outline: 0 none;}
    }
  }
  &_login {max-width: 490px; width: 100%; display: flex; flex-direction: column;
    @media (max-width: $tablet) {max-width: none; padding: 5px 16px 0; box-sizing: border-box;}
    &_links {margin-top: auto; margin-bottom: 16px;
      @media (max-width: $tablet) {display: none;}
    }
    &_inner {
      @media (max-width: $tablet) {max-height: 220px; transition: 1s linear .5s; overflow: hidden;}
    }
  }
  &_logo {margin: 19px $padding 32px 0; display: block;
    @media (max-width: $tablet) {margin: 0;}
    img {
      @media (max-width: $tablet) {max-width: 178px; max-height: 40px;}
    }
  }
  &_links {display: flex; justify-content: space-between;
    @media (max-width: $tablet) {flex-direction: column; padding: 0 16px; margin-top: 24px; gap: 24px;}
  }
  &_link {line-height: $icon-size;}
}

.menu {background: $bg-secondary; border-radius: $radius; padding: 6px; display: flex; justify-content: space-between;
  @media (max-width: $tablet) {background: none; display: block; padding: 0 16px;}
  &_item {margin-right: 4px;
    &:last-of-type {margin-right: 0;}
  }
  &_filters {
    @media (max-width: $tablet) {display: none;}
  }
  &_items {
    @media (max-width: $tablet) {max-height: 350px; transition: 1s linear .5s; overflow: hidden;}
  }
  &_search {
    .menu_items {
      @media (max-width: $tablet) {max-height: 0; transition: 0s; overflow: hidden;}
    }
    .menu_filters {
      @media (max-width: $tablet) {display: block;}
    }
  }
  &_open {
    @media (max-width: $tablet) {transition: 0s; height: auto;}
  }
  & &_item {
    @media (max-width: $tablet-wide) {padding: 4px 18px;}
    @media (max-width: $tablet) {background: none; border: none; color: $black; display: block; margin-right: 0; margin-bottom: 4px; padding: 10px 32px; line-height: 1.35;}
    &:last-of-type {
      @media (max-width: $tablet) {margin-bottom: 0;}
    }
    &__active {
      @media (max-width: $tablet) {background-color: $light-gray;}
    }
  }
}

.login {background: $bg-secondary; padding: 6px; border-radius: 0 0 $radius $radius;
  @media (max-width: $tablet) {border-radius: $radius; padding: 16px; margin-bottom: 22px;}
  &_form {
    &_col {
      @media (max-width: $mobile) {margin-bottom: 8px;}
    }
  }
  &_link {color: $text-opposite; margin-top: 4px; display: inline-block;
    &__first {margin-left: 5px;}
    &__logout {margin-top: 0; margin-left: 40px;
      @media (max-width: $mobile) {margin-left: 0;}
    }
  }
  &_subject {color: $text-opposite;}
  &__logined {padding: 16px; display: flex; justify-content: center;
    @media (max-width: $mobile) {flex-direction: column; align-items: center; gap: 16px;}
  }
  &_accountcreate {
    &_icon {display: none;
      @media (max-width: $mobile) {display: inline;}
    }
    @media (max-width: $tablet) {display: none;}
  }
  &_forgotpassword {
    @media (max-width: $tablet) {text-align: center; margin-top: 8px;}
  }
}

.search {display: inline-flex;
  @media (max-width: $tablet) {display: flex; flex-direction: column; gap: 5px}
  &_input {display: inline-block;}
  &_action {margin-left: 5px;
    @media (max-width: $tablet) {margin: 0;}
    &_text {display: none;
      @media (max-width: $tablet) {display: inline-block;}
    }
    & &_button {
      @media (max-width: $tablet) {width: 100%;}
    }
  }
}

.body {display: block; gap: $padding; margin: $padding 0 0;
  grid-template-columns: 286px minmax(0, 1fr); grid-auto-rows: auto;
  grid-template-areas: "sidebar content";
  &__showsidebar {display: grid;
    @media (max-width: $tablet) {grid-template-areas: "sidebar" "content"; grid-template-columns: 1fr;}
  }
  &_sidebar {grid-area: sidebar;}
  &_content {grid-area: content;}
}

.sidebar {background: $bg-secondary; color: $text-opposite; border-radius: $radius $radius 0 0; position: relative; margin-bottom: 44px;
  @media (max-width: $tablet) {background: $white; border-radius: $radius-small; margin-bottom: 0; display: grid; grid-template-rows: 55px 0fr; grid-template-areas: "action" "menu"; transition: .5s;}
  &::after {content: ''; position: absolute; top: 100%; left: 0; right: 0;
    background: url(../../../public/images/sidebar-bottom.svg); height: 44px;
    @media (max-width: $tablet) {content: none;}
  }
  &_title {@extend .title; background: $primary; border-radius: $radius $radius 0 0; padding: 12px $padding 13px;
    &__desktop {
      @media (max-width: $tablet) {display: none;}
    }
    &__tablet {display: none; border-radius: $radius-small; justify-content: space-between;
      @media (max-width: $tablet) {display: flex;}
    }
    &_loader {display: flex;}
  }
  &_menu {
    @media (max-width: $tablet) {grid-area: menu; overflow: hidden;}
    &__open {grid-template-rows: 55px 1fr;}
  }
  &_items {padding: $padding; padding-bottom: 12px;
    @media (max-width: $tablet) {padding: 25px 16px; padding-bottom: 25px;}
    &_inner {min-height: 152px; display: flex; flex-flow: column; max-height: 0; height: 0; animation: visible-sidebar-items .5s steps(1, end) forwards; overflow: hidden;}
    &_loader {position: absolute; left: 50%; transform: translateX(-50%);
      @media (max-width: $tablet) {display: none;} 
    }
  }
  &_item {line-height: 30px; margin: 0 30px; padding: 4px 6px 3px; border-bottom: 1px solid $gray; position: relative;
    @media (max-width: $tablet) {border: none; margin-bottom: 5px;}
    &.active {font-weight: bold;}
  }
  &_icon {display: inline-block; position: absolute; left: -30px; top: 4px;}
  &_link {
    @media (max-width: $tablet) {color: $black;}
  }
  &_button {border: none; outline: 0 none; background: none; display: block; color: $white; width: 100%; grid-area: action; display: none;
    @media (max-width: $tablet) {display: block;}
  }
}

@keyframes visible-sidebar-items {
  0% {max-height: 0; height: 0; opacity: 0;}
  100% {height: auto; max-height: 1500px; opacity: 1;}
}

:export {
  tablet: $tablet;
}