/* Colors */
$black: #000000;
$blue: #3881C4;
$blue-alt: #4695DE;
$green: #6DB23D;
$green-alt: #7BC647;
$gray: #4D4D4D;
$silver: #999999;
$light-gray: #EBEBEB;
$dark-gray: #333333;
$white: #FFFFFF;
$lighter-gray: #F2F2F2;
$red: #FF3131;

$primary: $blue;
$primary-active: $blue-alt;
$secondary: $green;
$secondary-active: $green-alt;
$bg: $light-gray;
$bg-secondary: $dark-gray;
$bg-box: $white;
$bg-input: $white;
$bg-active: $lighter-gray;
$text: $black;
$text-opposite: $white;
$subtext: $silver;
$error: $red;

/* Breakpoints */
$tablet-wide: 1150px;
$tablet: 1024px;
$tablet-small: 860px;
$mobile: 767px;

$radius: 12px;
$radius-small: 8px;

$text-small: 0.857rem;
$text-default: 14px;
$text-large: 2.285rem;
$text-h1: 1.928rem;
$text-h2: 1.5714rem;
$text-h3: 1.428rem;

$icon-size: 30px;

$padding: 20px;
$padding-small: 10px;
$margin: 20px;

