@import 'variables';
@import 'typo';
@import 'fonts';
@import 'grid';
@import 'utils';

html, body {padding: 0; margin: 0; font-size: $text-default; line-height: 1.428;
  color: $text; font-family: OpenSans, Arial, sans-serif; min-width: 375px;}
h1, h2, h3 {margin: 0; padding: 0;}
a, button, input, select, textarea {outline: none; margin: 0; padding: 0; font-family: OpenSans, Arial, sans-serif;}

a {color: inherit;text-decoration: none; cursor: pointer;}
p {margin: 0 0 $padding-small; @extend .text}

ul {list-style: none; margin: 0; padding: 0;}

.react-pdf__Page {
  .react-pdf__Page__svg {width: 100% !important; height: auto !important;}
  svg {transform: scale(1.235); transform-origin: 50% 50%; width: 100%; height: auto;}
}

.container {width: 100%; height: 100%; max-width: 1240px; margin: 0 auto; padding: 0 $padding; box-sizing: border-box;
  @media (max-width: $tablet) {padding: 0 16px;}
}

.grecaptcha-badge { 
  visibility: hidden !important;
}
